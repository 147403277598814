import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useTranslate from '@hooks/userTranslate';

import Modal from 'components/common/modal'

import { availableLanguages } from '../../i18nextInit'

import './styles.scss';

interface ILanguageSelector {
    className?: string;
}

const LanguageSelector: React.FC<ILanguageSelector> = (props: ILanguageSelector) => {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(localStorage.getItem('userLanguage') || i18n.language);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const t = useTranslate();

    const { className } = props;

    const handleCloseModal = useCallback(() => {
        setIsOpen(false)
    }, []);

    const handleOpenModal = useCallback(() => {
        setIsOpen(true)
    }, []);

    const handleChange = useCallback((language: string) => {
        setCurrentLanguage(language)
        i18n.changeLanguage(language).then(() => {
            localStorage.setItem('userLanguage', language);
            handleCloseModal()
        })
    }, [setCurrentLanguage, i18n, handleCloseModal]);

    return (
        <>
            <div className={`currentLanguage ${className ? className : ''}`} onClick={handleOpenModal}>
                <img src={`/languageIcons/${currentLanguage}.png`} alt={`${currentLanguage} icon`} />
            </div>
            <Modal
                isOpen={isOpen}
                onClose={handleCloseModal}
                caption='Select language'
                width='auto'

            >
                <>
                    <ul className='languageList'>
                        {
                            availableLanguages.map((lang: string) => {
                                return (
                                    <li id={lang} key={`lang-${lang}`} value={lang} onClick={() => handleChange(lang)}>
                                        <div className={`languageItem ${lang === currentLanguage && 'selected'}`}>
                                            <img src={`/languageIcons/${lang}.png`} alt={`${lang} icon`} />
                                            {t(`languages.${lang}`)}
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </>
            </Modal>
        </>
    );
}

export default LanguageSelector;