import { combineReducers } from 'redux';

import CurrentUserReducer from '@reducers/currentUser';
import { IUserData } from '@reducers/models/user';

export interface IStore {
    currentUser: IUserData;
}

const allReducers = combineReducers<IStore>({
    currentUser: CurrentUserReducer
});

export default allReducers