import React, {useCallback, useState, useRef, InputHTMLAttributes, ChangeEvent} from 'react';
import { FiX, FiCalendar, FiClock, FiPhone, FiSearch, FiFile, FiMail } from 'react-icons/fi';

import './styles.scss';

export interface IInputPropTypes extends InputHTMLAttributes<HTMLInputElement>{
    theme?: 'main' | 'secondary' | 'warning' | 'error' | 'disabled';
    error?: string;
    isClear?: boolean;
}

const Input: React.FC<IInputPropTypes> = (props: IInputPropTypes) => {
    const { title, theme = 'main', onChange, type = 'text', error, required, isClear } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [isFocus, setIsFocus] = useState<boolean>(false)

    const ICON_MAP = {
        'date': <FiCalendar />,
        'datetime-local': <FiCalendar />,
        'month': <FiCalendar />,
        'week': <FiCalendar />,
        'tel': <FiPhone />,
        'time': <FiClock />,
        'password': null,
        'text': null,
        'search': <FiSearch />,
        'number': null,
        'file': <FiFile />,
        'email': <FiMail />
    }

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event)
        }
    }, [onChange])

    const handleFocus = useCallback(() => {
        setIsFocus(true);
    }, [])

    const handleLeave = useCallback(() => {
        setIsFocus(false);
    }, [])

    const handleClear = useCallback(() => {
        if(inputRef.current) {
            inputRef.current.value = '';
            if (onChange) {
                onChange({
                    target: {value: ''}
                } as ChangeEvent<HTMLInputElement>)
            }
        }

    }, [inputRef, onChange])

    const handleClickIcon = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }, [inputRef])

    // @ts-ignore
    const TypeIcon = ICON_MAP[type] || null;

    const nativeInputProps = { ...props }
    delete nativeInputProps.isClear;

    return (
        <div className={`input ${theme} ${isFocus && 'inputFocus'} ${error && 'inputError'}`}>
            <div className='inputWrapper'>
                <span className='inputCaption'>{title}{required && '*'}{error && ` (${error})`}</span>
                <input
                    ref={inputRef}
                    {...nativeInputProps}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleLeave}
                />
            </div>
            <div className='inputIcons'>
                {(isClear && inputRef.current?.value) && (
                    <div className='inputIcon action' onClick={handleClear}>
                        <FiX />
                    </div>
                )}
                {
                    TypeIcon && (
                        <div onClick={handleClickIcon}>
                            {TypeIcon}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

Input.defaultProps = {
    theme: 'main'
}

export default Input;
