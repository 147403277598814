import React, { useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import sha256 from 'crypto-js/sha256';

import { userApi } from '@api/index';

import useTranslate from '@hooks/userTranslate'

import Input from '@components/common/input';
import Button from '@components/common/button';
import FormicField from '@components/common/formicField';
import EnterSMS from '@components/enterSMS'



import './styles.scss';

interface ILoginPageProps {

}

interface IFormValues {
    phoneNumber: string;
    password: string;
    repeatPassword?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
}

interface INewUserCode {
    id: string;
    code?: string;
}

interface ILocationState {
    redirect: string;
}

const initialFormValues: IFormValues = {
    phoneNumber: '',
    password: '',
    repeatPassword: '',
    firstname: '',
    lastname: '',
    email: '',
}

const LoginPage: React.FC<RouteComponentProps<ILoginPageProps>> = (props: RouteComponentProps<ILoginPageProps>) => {
    const [isSignUp, setIsSigneUp] = useState<boolean>(false);
    const [isEnterSMSCode, setIsEnterSMSCode] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('')
    const t = useTranslate()

    const validationSchema = Yup.object().shape({
        phoneNumber: Yup.string()
            .matches(/^(\+?)\(?([0-9]{1,3}?)\)?[-. ]?([0-9]{2,4}?)?[- ]?([0-9]{4,8}?)$/, 'Invalid phone number')
            .required(t('validation.required')),
        password: Yup.string()
            .min(8, t('validation.minlength', {count: 8}))
            .required(t('validation.required')),
        ...(isSignUp ? {
            repeatPassword: Yup.string()
                .min(8, t('validation.minlength', {count: 8}))
                .required(t('validation.required')),
            firstname: Yup.string()
                .required(t('validation.required')),
            lastname: Yup.string()
                .required(t('validation.required')),
            email: Yup.string()
                .email(t('validation.validEmail'))
                .required(t('validation.required'))
        } : {})
    })


    const formik = useFormik<IFormValues>({
        initialValues: initialFormValues,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {}
    });

    const handleAction = useCallback(() => {
        setIsSigneUp(!isSignUp)
    }, [isSignUp, setIsSigneUp])

    const handleSubmit = useCallback(async () => {
        if(formik.values.password !== formik.values.repeatPassword && isSignUp) {
            formik.setFieldError('password', t('validation.matchPassword'))
            formik.setFieldError('repeatPassword', t('validation.matchPassword'))
        } else {
            Object.keys(initialFormValues).forEach((field) => {
                try {
                    formik.validateField(field)
                } catch (e) {

                }
            })
            const userData = {
                ...formik.values,
                password: sha256(`wp_${formik.values.password}`).toString(),
                phoneNumber: formik.values.phoneNumber.replace(/[+\s()]/g, '')
            }
            delete userData.repeatPassword
            if(formik.isValid && isSignUp) {
                const newUser = await userApi.signUpUser(JSON.stringify(userData))
                if(newUser.id) {
                    setIsEnterSMSCode(true)
                    setUserId(newUser.id)
                }
            }
            if(formik.isValid && !isSignUp) {
                const { history, location } = props;
                userApi.loginUser(JSON.stringify(userData))
                    .then(() => {
                        const redirect = new URLSearchParams(location.search).get('redirect');
                        if(redirect) {
                            history.push(redirect)
                        } else {
                            history.push('/')
                        }
                    })
                    .catch((err) => console.log(err))
            }
        }
    }, [isSignUp, formik, props, t])

    const handleConfirm = useCallback((data: INewUserCode) => {
        const { history, location } = props;
        userApi.confirmUser(JSON.stringify(data))
            .then((confirmData) => {
                localStorage.setItem('userData', JSON.stringify(confirmData))
                const locState = location.state as ILocationState;
                if(locState.redirect) {
                    history.push(locState.redirect)
                }
            })
    }, [props])


    return (
      <div className='loginPageWrapper'>
          <div className='loginForm'>
              <div className="caption">
                  {isSignUp ? t('authorization.signUp') : t('authorization.logIn')}
              </div>
              {!isEnterSMSCode ? (
                  <div className="form">
                      <FormicField
                          formik={formik}
                          type='tel'
                          title={t('authorization.phoneNumber')}
                          required
                          isClear
                          InputComponent={Input}
                          name='phoneNumber'
                      />
                      <FormicField
                          formik={formik}
                          type='password'
                          title={t('authorization.password')}
                          required
                          isClear
                          InputComponent={Input}
                          name='password'
                      />
                      {isSignUp && (
                          <>
                              <FormicField
                                  formik={formik}
                                  type='password'
                                  title={t('authorization.repeatPassword')}
                                  required
                                  isClear
                                  InputComponent={Input}
                                  name='repeatPassword'
                              />
                              <FormicField
                                  formik={formik}
                                  type='text'
                                  title={t('authorization.firstName')}
                                  required
                                  isClear
                                  InputComponent={Input}
                                  name='firstname'
                              />
                              <FormicField
                                  formik={formik}
                                  type='text'
                                  title={t('authorization.lastName')}
                                  required
                                  isClear
                                  InputComponent={Input}
                                  name='lastname'
                              />
                              <FormicField
                                  formik={formik}
                                  type='email'
                                  title={t('authorization.email')}
                                  required
                                  isClear
                                  InputComponent={Input}
                                  name='email'
                              />
                          </>
                      )}
                      <div className='selectAction'>
                          <span onClick={handleAction}>{isSignUp ? 'or Log in' : 'or Sign up'}</span>
                      </div>
                      <Button
                          caption={isSignUp ? t('authorization.signUp') : t('authorization.logIn')}
                          onClick={handleSubmit}
                      />
                  </div>
              ) : (
                  <EnterSMS digits={6} id={userId} onComplete={handleConfirm} />
              )}

          </div>
      </div>
    );
};

export default withRouter(LoginPage);
