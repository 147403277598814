import { useSelector } from 'react-redux';

import { IStore } from '@reducers/index';
import { IUserData } from '@reducers/models/user';

const useIsUserLoggedIn = (): boolean => {
    const data = useSelector<IStore>((state) => state.currentUser) as IUserData;

    return Boolean(data.id);
}

export default useIsUserLoggedIn