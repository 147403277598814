import React from 'react';
import { Switch } from 'react-router-dom';

// import ROLES from '@constants/userRoles';

import ExcursionStream from '@containers/excursionStream';
import MainPage from '@containers/mainPage';
import LoginPage from '@containers/loginPage';
import CreateExcursionPage from '@containers/createExcursionPage';

import PrivateRoute from 'components/routes/privateRoute';

const Routes: React.FC = () => {
    return(
        <Switch>
            <PrivateRoute
                path='/'
                exact
                roles={[]}

            >
                <MainPage />
            </PrivateRoute>
            <PrivateRoute
                path='/excursion/:id/edit'
                exact
                roles={[]}
            >
                <CreateExcursionPage />
            </PrivateRoute>
            <PrivateRoute
                path='/login'
                roles={[]}
            >
                <LoginPage />
            </PrivateRoute>
            <PrivateRoute 
                path='/stream'
                exact
                roles={[]}
            >
                <ExcursionStream />
            </PrivateRoute>
        </Switch>
    )
}

export default Routes;