import reduxFetch from '@helpers/reduxFetch';

import { ICreatedExcursion } from '@api/excursionApi/types/ICreatedExcursion';
import { IExcursion } from 'types/IExcursion';

import {
    CREATE_EXCURSION,
    EDIT_EXCURSION,
    GET_EXCURSION
} from '@constants/requests';

const excursionApi = {
    createExcursion: async (excursionData: any): Promise<ICreatedExcursion> => {
        return reduxFetch.post(`/excursion/create`, { requestType: CREATE_EXCURSION, body: excursionData })
    },
    addExcursionImages: async (excursionImages: any, excursionId: string): Promise<void> => {
        return reduxFetch.post(`/excursion/${excursionId}/addExcursionImages`, { body: excursionImages, headers: {} })
    },
    addExcursionPath: async (excursionPath: any, excursionId: string): Promise<void> => {
        return reduxFetch.post(`/excursion/${excursionId}/addExcursionPath`, { body: excursionPath })
    },
    editExcursion: async (excursionId: string, excursionData: any): Promise<void> => {
        return reduxFetch.put(`/excursion/${excursionId}`, { requestType: EDIT_EXCURSION, body: excursionData })
    },
    getExcursion: async (excursionId: string): Promise<IExcursion> => {
        return reduxFetch.get(`/excursion/${excursionId}`, { requestType: GET_EXCURSION })
    },
}

export default excursionApi;