import React, {
    useCallback,
    useState,
    useRef,
    useEffect,
    InputHTMLAttributes,
    ChangeEvent,
    SyntheticEvent
} from 'react';
import { FiX } from 'react-icons/fi';

import './styles.scss';

export interface IItem {
    [name: string]: string;
}

export interface ISelectPropTypes extends InputHTMLAttributes<HTMLInputElement>{
    theme?: 'main' | 'secondary' | 'warning' | 'error' | 'disabled';
    error?: string;
    isClear?: boolean;
    autocomplete?: boolean;
    options?: IItem[];
    keyName?: string;
    valueName?: string;
    changeOutput?: (option: IItem) => IItem | string;
    onTextChange?: (text: string) => void;
}

interface IOption extends HTMLElement {
    ['data-code']: string;
}

const Select: React.FC<ISelectPropTypes> = (props: ISelectPropTypes) => {
    const {
        title,
        theme = 'main',
        onChange,
        error,
        required,
        isClear,
        autocomplete = false,
        options=[],
        keyName='code',
        valueName='value',
        changeOutput=(option) => option,
        onTextChange,
        defaultValue
    } = props;

    const [selectValue, setSelectValue] = useState<string>();

    const inputRef = useRef<HTMLInputElement>(null);
    const selectRef = useRef<HTMLDetailsElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (defaultValue) {
            setSelectValue(defaultValue as string)
            if (onTextChange) {
                onTextChange(defaultValue as string);
            }
            selectRef.current?.removeAttribute('open')
        }
    }, [defaultValue, selectRef, onTextChange])

    useEffect(() => {
        if (selectValue && selectValue !== defaultValue) {selectRef.current?.setAttribute('open', '')}
    }, [options, selectValue, defaultValue])

    const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSelectValue(event.target.value);
        if (onTextChange) {
            onTextChange(event.target.value);
        }
    }, [setSelectValue, onTextChange]);

    const handleChange = useCallback((data) => {
        if (onChange) {
            onChange({target: { value: data }} as ChangeEvent<HTMLInputElement>);
        }
    }, [onChange]);

    const handleOptionClick = useCallback((event: SyntheticEvent) => {
        const item = event.target as IOption;
        const code = item.getAttribute('data-code');
        const option = options.filter((opt) => opt[keyName] === code)[0];
        handleChange(changeOutput(option));
        setSelectValue(item.innerText);
        selectRef.current?.removeAttribute('open')
    }, [setSelectValue, selectRef, changeOutput, handleChange, options, keyName]);

    const handleClear = useCallback(() => {
        setSelectValue('');
        if (onTextChange) {onTextChange('');}
        handleChange({target: { value: '' }} as ChangeEvent<HTMLInputElement>);
    }, [setSelectValue, handleChange, onTextChange]);

    const isBottom = useCallback(() => {
        const offset = selectRef.current?.getBoundingClientRect().y || 0;
        const bottomDelta = window.innerHeight - (offset + 54);
        const optionsHeight = 200;
        return bottomDelta - optionsHeight > 0
    }, [selectRef]);

    const nativeInputProps = { ...props };
    delete nativeInputProps.isClear;

    return (
        <div className='selectWrapper'>
            <details ref={selectRef}>
                <summary className={`${theme} ${isBottom() ? 'bottomPosition' : 'topPosition'}  ${error && 'inputError'}`}>
                    <div className='inputWrapper'>
                        <span className='inputCaption'>{title}{required && '*'}{error && ` (${error})`}</span>
                        <input
                            ref={inputRef}
                            disabled={!autocomplete}
                            value={selectValue}
                            autoComplete='no'
                            onChange={handleChangeValue}
                        />
                    </div>
                    <div className='inputIcons'>
                        {(isClear && selectValue) && (
                            <div className='inputIcon action' onClick={handleClear}>
                                <FiX />
                            </div>
                        )}
                    </div>
                </summary>
                <ul
                    ref={optionsRef}
                    className={isBottom() ? 'bottomPosition' : 'topPosition'}
                    onClick={handleOptionClick}
                >
                    {
                        options.map((option, i) => (
                            <li
                                key={`option-${option[keyName]}-${i}`}
                                data-code={option[keyName]}
                            >
                                {option[valueName]}
                            </li>
                        ))
                    }
                </ul>
            </details>
        </div>
    );
};

Select.defaultProps = {
    theme: 'main'
}

export default Select;
