import React, { useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import md5 from 'crypto-js/md5';
import notify from '@helpers/notify'

import { ILanguageDictionary } from 'types/ILanguageDictionary';
import { ICityDictionary } from 'types/ICityDictionary';

import { excursionApi, dictionaryApi } from '@api/index'

import useTranslate from '@hooks/userTranslate'

import EditMap from '@components/editMap';
import Slider, { IGallery } from '@components/slider';
import GalleryUploader from '@components/galleryUploader';
import Input from '@components/common/input';
import Button from '@components/common/button';
import TextArea from '@components/common/textArea';
import FormicField from '@components/common/formicField';
import Select from '@components/common/select';
import { IPathPoint } from '@components/map/mapPath';

import './styles.scss';

interface IFormValues {
    name?: string;
    description?: string;
    startDateTime?: string;
    cityName?: string;
    duration?: string;
    language?: string;
    countryCode?: string;
    price?: number;
}

const initialFormValues: IFormValues = {
    name: '',
    description: '',
    startDateTime: '',
    cityName  : '',
    duration: '',
    language: '',
    countryCode: '',
    price: 0
};

interface IPathParamsType {
    id: string;
}

const CreateExcursionPage: React.FC<RouteComponentProps<IPathParamsType>> = () => {
    const [images, setImages] = useState<IGallery[]>([]);
    const [activeImage, setActiveImage] = useState<number>(0);
    const [languageList, setLanguageList] = useState<ILanguageDictionary[]>([]);
    const [citiesList, setCitiesList] = useState<ICityDictionary[]>([]);
    const [excursionPath, setExcursionPath] = useState<IPathPoint[]>([]);
    const [formicInitial] = useState<IFormValues>(initialFormValues);
    const [defaultLanguage] = useState<string>('');

    const t = useTranslate()

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required(t('validation.required')),
        description: Yup.string()
            .required(t('validation.required')),
        startDateTime: Yup.string()
            .required(t('validation.required')),
        cityName: Yup.string()
            .required(t('validation.required')),
        duration: Yup.string()
            .required(t('validation.required')),
        language: Yup.string()
            .required(t('validation.required')),
        countryCode: Yup.string()
            .required(t('validation.required')),
        price: Yup.number()
            .required(t('validation.required')),
    })

    const formik = useFormik<IFormValues>({
        initialValues: formicInitial,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {}
    });

    const handleCreateExcursion = useCallback((excursionData, imagesList, path) => {
        excursionApi.createExcursion(JSON.stringify(excursionData))
            .then(async (response) => {
                return Promise.all([
                    excursionApi.addExcursionImages(imagesList, response.id),
                    excursionApi.addExcursionPath(JSON.stringify(path), response.id)
                ])
            })
            .then(() => {
                notify.success('Excursion created')
            })
            .catch((err) => {
                notify.error(err)
            })
    }, [])

    const handleSubmit = useCallback(() => {
        const isPathAndImages = (images.length && excursionPath.length)
        Object.keys(initialFormValues).forEach((field) => {
            formik.validateField(field);
        })

        if (!images.length) {
            notify.error(t('validation.requiredExcursionPhoto'))
        }

        if (!excursionPath.length) {
            notify.error(t('validation.requiredExcursionPath'))
        }

        if(formik.isValid && isPathAndImages) {
            const formData = new FormData();
            images.forEach((image: IGallery) => {
                const imgName = md5(`${image.url}${new Date()}`).toString()
                formData.append(imgName, image.file ? image.file : '')
                if (image.description) {
                    formData.append(`${imgName}_description`, image.description)
                }
            })

        handleCreateExcursion(formik.values, formData, excursionPath)
        }
    }, [images, excursionPath, formik, t, handleCreateExcursion]);

    const handleChangeTextLanguage = useCallback(async (text) => {
        const languages = await dictionaryApi.languages(text)
        setLanguageList(languages)
    }, [setLanguageList]);

    const handleChangeTextPlace = useCallback(async (text) => {
        const cities = await dictionaryApi.cities(text)
        setCitiesList(cities)
    }, [setCitiesList]);

    const handlePlaceChange = useCallback((event) => {
        formik.setFieldValue('cityName', event.target.value.name || '')
        formik.setFieldValue('countryCode', event.target.value.countryCode || '')
    }, [formik]);

    return (
      <div className='createExcursionPage'>
          <div className='excursionForm'>
              <h1>{t('excursion.createCaption')}</h1>
              <p>
                  {t('excursion.createHelperText')}
              </p>
              <div className='sliderWrapper'>
                  <Slider images={images} onImageSelect={setActiveImage}>
                      <GalleryUploader
                          images={images}
                          activeImage={activeImage}
                          onChangeImagesList={setImages}
                      />
                  </Slider>
              </div>
              <div className='fieldWrapper'>
                  <FormicField
                      className='formField'
                      formik={formik}
                      type='text'
                      title={t('excursion.name')}
                      required
                      isClear
                      InputComponent={Input}
                      name='name'
                  />
                  <FormicField
                      className='formField'
                      formik={formik}
                      type='datetime-local'
                      title={t('excursion.startDateTime')}
                      required
                      isClear
                      InputComponent={Input}
                      name='startDateTime'
                  />
                  <FormicField
                      className='formField'
                      formik={formik}
                      name='cityName'
                      title={t('excursion.place')}
                      required
                      isClear
                      InputComponent={Select}
                      autocomplete
                      keyName='name'
                      valueName='name'
                      options={citiesList as any[]}
                      onTextChange={handleChangeTextPlace}
                      onChange={handlePlaceChange}
                      defaultValue={formik.values.cityName}
                  />
                  <FormicField
                      formik={formik}
                      title={t('excursion.description')}
                      required
                      isClear
                      InputComponent={TextArea}
                      name='description'
                      rows={3}
                  />
                  <FormicField
                      className='formField'
                      formik={formik}
                      type='time'
                      title={t('excursion.duration')}
                      required
                      isClear
                      InputComponent={Input}
                      name='duration'
                  />
                  <FormicField
                      className='formField'
                      formik={formik}
                      name='language'
                      title={t('excursion.language')}
                      required
                      isClear
                      InputComponent={Select}
                      autocomplete
                      valueName='nativeName'
                      options={languageList as any[]}
                      changeOutput={(option) => option.code}
                      onTextChange={handleChangeTextLanguage}
                      defaultValue={defaultLanguage}
                  />
                  <FormicField
                      className='formField'
                      formik={formik}
                      type='number'
                      title={t('excursion.price')}
                      required
                      isClear
                      InputComponent={Input}
                      name='price'
                  />
                  <div className='formField buttons'>
                      <Button caption={t('excursion.publishButton')} onClick={handleSubmit} />
                  </div>
                  <div className='formField buttons'>
                      <Button theme='light' caption={t('excursion.publishCancel')} />
                  </div>
              </div>
          </div>
          <div className='mapWrapper'>
              <EditMap pathPoints={excursionPath} onEditPathPoints={setExcursionPath} />
          </div>
      </div>
    );
};

export default withRouter(CreateExcursionPage);
