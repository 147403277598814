import React, { useCallback } from 'react';
import { Popup } from 'react-leaflet';

import checkPointMarker from '@assets/checkPointMarker.svg';
import dotMarker from '@assets/dotMarker.svg';
import deleteIcon from '@assets/delete_icon.svg';

import './styles.scss';

interface IEditPopupProps {
    index: number;
    setPointType: (index: number, type: string) => void;
    deletePoint?: (index: number) => void;
}

const EditPopup: React.FC<IEditPopupProps> = (props: IEditPopupProps) => {
    const { index, setPointType, deletePoint } = props;

    const setDotType = useCallback(() => {
        setPointType(index, 'STEP')
    }, [setPointType, index]);

    const setCheckPointType = useCallback(() => {
        setPointType(index, 'CHECK_POINT')
    }, [setPointType, index]);

    const handleDeletePoint = useCallback(() => {
        if (deletePoint) {
            deletePoint(index)
        }
    }, [deletePoint, index]);

    return (
        <Popup>
            <div className='editPopup'>
                <div className='icon' onClick={setDotType}>
                    <img src={dotMarker} alt="" />
                </div>
                <div className='icon' onClick={setCheckPointType}>
                    <img src={checkPointMarker} alt="" />
                </div>
                <div className='icon delete' onClick={handleDeletePoint}>
                    <img src={deleteIcon} alt="" />
                </div>
            </div>
        </Popup>
    );
}

export default EditPopup;