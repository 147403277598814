import jwt_decode from "jwt-decode";
import { request, success, error } from '@helpers/prepareActionTypes';

import { LOGIN_USER, CONFIRM_USER, LOGOUT_USER , UNAUTHORIZED} from '@constants/requests';

import { IUserData } from '@reducers/models/user';

const userDataFromStorage: IUserData = localStorage.getItem('jwt-token') ? jwt_decode(localStorage.getItem('jwt-token') || '') : {} as IUserData

const init: IUserData = {
    ...userDataFromStorage
};


export function CurrentUser(state: IUserData = init, action: any): IUserData {

    switch (action.type) {
        case request(LOGIN_USER):
            return state;
        case success(LOGOUT_USER):
            localStorage.removeItem('jwt-token');
            return {} as IUserData;
        case success(UNAUTHORIZED):
            localStorage.removeItem('jwt-token');
            window.location.href = `/login?redirect=${window.location.pathname}`
            return {} as IUserData;
        case success(LOGIN_USER):
            const userData = jwt_decode(action.payload.token);
            localStorage.setItem('jwt-token', action.payload.token)
            return {
                ...state,
                ...userData as IUserData,
            };
        case success(CONFIRM_USER):
            return {
                ...state,
                ...action.payload
            };
        case error(LOGIN_USER):
            return state;
        default:
            return state;

    }
}

export default CurrentUser;