import React from 'react';
import { FaRegTimesCircle, FaTelegram } from "react-icons/fa";
 
import useTranslate from '@hooks/userTranslate';

interface IChatBar {
  onClickChat: () => void;
}


const ChatBar:React.FC<IChatBar> = ({onClickChat}) => {
  const t = useTranslate();

  return(
    <div className="chat-bar">
      <header>
        <span><b>{t('chatBar.chatOfTheFieldTrip')}</b> (Members: 68)</span>
        <FaRegTimesCircle onClick={onClickChat} />
      </header>
      <div className="message-input">
        <div className="message-item">
          <div className="photo" /> 
          <div className="message">
            <div>
              <b>Thomas Mann</b>
              <span>10:06</span>
            </div>           
            <p>Guten Abend! Das ist schon meine vierte Ekskursion auf dieser Plattform! Ich mag die gern! 😄</p>
          </div> 
        </div>
        <div className="message-line">
          <textarea placeholder="Write here" rows={1} cols={2} />
          <FaTelegram className="send-message" />
        </div>
      </div>
    </div>
  )
}

export default ChatBar;