import React, { useCallback } from 'react';

import useTranslate from '@hooks/userTranslate';

import Button from '@components/common/button';
import Slider from'@components/slider';


import './styles.scss';

const images = [
    {
        description: '111',
        url: 'https://static9.depositphotos.com/1010683/1238/i/600/depositphotos_12384660-stock-photo-singapore-city-skyline-at-night.jpg'
    },
    {
        description: '222',
        url: 'https://lh3.googleusercontent.com/proxy/iLVpOAq20b40LhjUN-qOnpIwOv8LAsvtWy4TLfSOYdsKYtLGXax-fhsOWjoXcPKxwFqY6-TVR1hyadu7FC86mCdZG2ke1xm1qu2klByOsI_e3i3N6gf_ew'
    },
    {
        description: '333',
        url: 'https://bipbap.ru/wp-content/uploads/2017/07/cities-to-visit-once-20.jpg'
    },
    {
        description: '444',
        url: 'https://planetofhotels.com/guide/sites/default/files/styles/node__blog_post__bp_banner/public/2021-02/Colmar-Old-Town.jpg'
    },
]

interface IPlacesItemPropTypes {
    place: any;
    index: number;
    onChange?: (place: any, index: number) => void;
}

const PlacesItem: React.FC<IPlacesItemPropTypes> = (props) => {
    const { place, index, onChange } = props;
    const t = useTranslate()

    const handleChangePlace = useCallback(() => {
        if (onChange) {
            onChange(place, index);
        }
    }, [place, index, onChange])

    return (
        <div className='placesItem' onClick={handleChangePlace}>
            <div className="gallery">
                <Slider images={images} />
            </div>
            <div className='dataWrapper'>
                <div className='row mainInfo'>
                    <span>{`Place ${index} (Country ${index})`}</span>
                    <span>10:00 - 12:00</span>
                </div>
                <div className='row guidInfo'>
                    <span>{t('excursion.guide')}{`: Guide${index}`}</span>
                    <span>{t('excursion.language')}{`: Language${index}`}</span>
                </div>
                <div className='row ticketsInfo'>
                    <span>{t('excursion.available_tickets')}:</span>
                    <span>{t('excursion.tickets', {free: 20, count: 51})}</span>
                </div>
                <div className='row buyInfo'>
                    <Button caption={t('excursion.buy_ticket')} />
                    <span>{`${Math.floor(Math.random() * 15)} €`}</span>
                </div>
            </div>
        </div>
    );
};

PlacesItem.defaultProps = {

}

export default PlacesItem;
