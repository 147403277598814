import React from 'react';
import { MdOutlineCamera } from 'react-icons/md';

import defaultAvatar from '@assets/default_avatar.svg';

import './styles.scss';

interface IAvatarProps {
    link?: string;
    width?: string;
    height? :string;
    onSelectPhoto?: (photo: File) => void;
}

const Avatar: React.FC<IAvatarProps> = (props: IAvatarProps) => {
    const {
        link,
        width = '50px',
        height = '50px',
        onSelectPhoto
    } = props;

    return (
        <div className='avatarWrapper'>
            <img
                className="avatar"
                style={{
                    width,
                    height
                }}
                src={link || defaultAvatar} alt="User avatar"
            />
            {
                onSelectPhoto && (
                    <div className='selectPhoto'>
                        <MdOutlineCamera />
                    </div>
                )
            }
        </div>
    );
}

export default Avatar;