import React, { useCallback } from 'react';
import { Icon , LatLngExpression } from 'leaflet';
import { Marker, Polyline } from 'react-leaflet';

import EditPopup from '@components/map/editPopup'
import Markers from '@components/map/markers';

export interface IPathPoint {
    typePoint: 'START' | 'CHECK_POINT' |'STEP' | 'FINISH';
    latitude: number;
    longitude: number;
}

const lineOptions = {
    color: 'blue',
    weight: 7,
    opacity: 0.8
};

interface IMapMarkers {
    [key: string]: Icon;
}

interface IMapPathProps {
    pathPoints? : IPathPoint[];
    isEdit?: boolean;
    onEditPoint?: (index: number, point: IPathPoint) => void;
    onDeletePoint?: (index: number) => void;
    isShowAllPath?: boolean;
}

const MapPath: React.FC<IMapPathProps> = (props: IMapPathProps) => {
    const { pathPoints = [], isEdit = false, onEditPoint, onDeletePoint, isShowAllPath } = props;

    const mapMarkers: IMapMarkers = {
        'STEP': Markers.dotMarker,
        'CHECK_POINT': Markers.checkPoint
    };

    const eventHandler = useCallback((id: number) => ({
            drag(e: any) {
                const newCoords = e.target.getLatLng();
                const currentPoint = {...pathPoints[id]}
                currentPoint.latitude = newCoords.lat
                currentPoint.longitude = newCoords.lng
                if (onEditPoint) {
                    onEditPoint(id, currentPoint)
                }
            }
        }),
        [pathPoints, onEditPoint]
    );

    const handleSetPointType = useCallback((id: number, type: string) => {
        const currentPoint = {...pathPoints[id]};
        currentPoint.typePoint = type as IPathPoint['typePoint'];
        if (onEditPoint) {
            onEditPoint(id, currentPoint)
        }
    }, [pathPoints, onEditPoint]);

    return (
        <>
            {pathPoints.length > 0 && (
                <>
                    <Polyline
                        pathOptions={lineOptions}
                        positions={pathPoints.map((point) => [point.latitude, point.longitude])
                            .filter((point, i) => isShowAllPath || i === 0)as LatLngExpression[]}
                    />
                    {
                        pathPoints.map((point, i) => {
                            if (i === 0) {
                                return (
                                    <Marker
                                        alt={String(i)}
                                        eventHandlers={eventHandler(i)}
                                        draggable={isEdit}
                                        key={`path_point_${i}`}
                                        position={[point.latitude, point.longitude] as LatLngExpression}
                                        icon={Markers.activeMarker}
                                    />
                                )
                            }
                            if ((!isEdit && point.typePoint === 'STEP') || !isShowAllPath) {return null}
                            return (
                                <Marker
                                    eventHandlers={eventHandler(i)}
                                    draggable={isEdit}
                                    key={`path_point_${i}`}
                                    position={[point.latitude, point.longitude] as LatLngExpression}
                                    icon={mapMarkers[point.typePoint]}
                                >
                                    <EditPopup index={i} setPointType={handleSetPointType} deletePoint={onDeletePoint} />
                                </Marker>
                            )
                        })
                    }
                </>
            )}
        </>
    );
}

export default MapPath;