import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import notify from '@helpers/notify'
import { request, success, error } from '@helpers/prepareActionTypes'

import settings from '@constants/settings';
import { UNAUTHORIZED } from '@constants/requests';

import allReducers from '@reducers/index';

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

interface IAction {
    type: string;
    payload?: any;
}

interface IStringParams {
    [name: string]: string;
}

interface IFetchParams {
    body?: any;
    headers?: IStringParams;
    requestType?: string;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(allReducers, composeEnhancers(applyMiddleware(thunk)));

export const setAction = (action: IAction): void => {
    store.dispatch(action)
}

const fetchActionWrapper = async <T extends unknown>(requestType: string, method: string, url: string, headers: IStringParams | null = null, body: any = {}): Promise<T> => {
    const token = localStorage.getItem('jwt-token');

    store.dispatch({type: request(requestType)});
    const paramsObject = {
        method,
        mode: 'cors',
        credentials: 'include',
        headers: {
            ...headers ? headers : {'Content-Type': 'application/json'},
            'Authorization': token ? `Bearer ${token}` : ''
        },
        body
    }
    if (method === 'GET') {
        delete paramsObject.body
    }
    return fetch(`${settings.serverUrl}${url}`, paramsObject as RequestInit)
        .then(async (response) => {
            if(response.status === 401) {
                console.log('401')
                store.dispatch({
                    type: success(UNAUTHORIZED)
                })
                return null
            }
            const data = await response.json()
            if(response.status >= 400) {
                notify.error(data.error)
                store.dispatch({
                    type: error(requestType),
                    payload: data
                })
                return Promise.reject(data.error)
            } else if(response.status < 400) {
                store.dispatch({
                    type: success(requestType),
                    payload: data
                })
                return data;
            }
        });
};


const reduxFetch = {
    get: async <T extends unknown>(url: string, options: IFetchParams | undefined = {}): Promise<T> => {
        return fetchActionWrapper(options.requestType || 'DEFAULT_ACTION', 'GET', url, options.headers);
    },
    post: async <T extends unknown>(url: string, options: IFetchParams): Promise<T> => {
        return fetchActionWrapper(options.requestType || 'DEFAULT_ACTION', 'POST', url, options.headers, options.body);
    },
    put: async <T extends unknown>(url: string, options: IFetchParams): Promise<T> => {
        return fetchActionWrapper(options.requestType || 'DEFAULT_ACTION', 'PUT', url, options.headers, options.body);
    },
    delete: async <T extends unknown>(url: string, options: IFetchParams): Promise<T> => {
        return fetchActionWrapper(options.requestType || 'DEFAULT_ACTION', 'DELETE', url, options.headers, options.body);
    }
}

export default reduxFetch;