import React, { useCallback } from 'react';
import ReactCodeInput from 'react-verification-code-input';

import './styles.scss';

interface IEnterSMS {
    digits: number;
    id: string;
    onComplete: (data: any) => void;
}

const EnterSMS: React.FC<IEnterSMS> = ( props: IEnterSMS ) => {
    const { digits, onComplete, id } = props;

    const handleComplete = useCallback((code) => {
        onComplete({
            id,
            code
        })
    }, [id, onComplete])

    return (
        <div className='enterSMSForm'>
            <div className='description'>
                A confirmation code has been sent to your phone, enter it in the field below.
            </div>
            {/*@ts-ignore*/}
            <ReactCodeInput fields={digits} onComplete={handleComplete} />
        </div>
    );
}

export default EnterSMS;