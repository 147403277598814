import { useTranslation } from 'react-i18next';

interface IParams {
    [key: string]: string | number;
}

type ITranslateFunc = (
    key: string,
    params?: IParams,
    modifyFunc?: (string: string) => string
) => string;

const useTranslate = (): ITranslateFunc => {
    const { t } = useTranslation();
    const noop = (str: string): string => str;

    const tr: ITranslateFunc = (key, params={}, modifyFunc = noop) => {
        let text = t(key);
        Object.keys(params).forEach((translateKey) => {
            const regExp = new RegExp(`{${translateKey}}`);
            text = text.replace(regExp, String(params[translateKey]));
        })

        return modifyFunc(text);
    }

    return tr
}

export default useTranslate