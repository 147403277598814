import React, {useCallback, InputHTMLAttributes} from 'react';

import { ISelectPropTypes } from '@components/common/select';
import { IInputPropTypes } from '@components/common/input';

import './styles.scss';

interface IFormicFieldPropTypes extends InputHTMLAttributes<HTMLInputElement>, IInputPropTypes, ISelectPropTypes {
    InputComponent: any;
    formik?: any;
    name?: string;
    rows?: number;
}

const FormicField: React.FC<IFormicFieldPropTypes> = (props: IFormicFieldPropTypes) => {
    const {
        onChange,
        formik,
        name = '',
        value
    } = props;
    const { InputComponent, className, ...restProps } = props;


    const handleChange = useCallback((event) => {
        if( formik ) {formik.setFieldValue(name, typeof event === 'object' ? event.target.value : event, true);}
        if (onChange) {onChange(event);}
    }, [onChange, formik, name])

    return (
        <div className={className ? className : 'defaultField'}>
            <InputComponent
                {...restProps}
                onChange={handleChange}
                value={formik ? formik.values[name] : value}
                error={formik ? formik.errors[name] : ''}
            />
        </div>
    );
};

FormicField.defaultProps = {
}

export default FormicField;
