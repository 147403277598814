import Alert from 'react-s-alert';

const settingsNotify = {
    position: 'top-right',
    effect: 'slide',
    beep: false,
    timeout: 5000,
    html: true
}

const prepareMessage = (type: string, message: string): string => {
    return `
            <h3 class='typeMessage'>${type}:</h3>
            <p class='message'>${message}</p>
            `
}

const notify = {
    success: (message: string, notClose: boolean = false): void => {
        Alert.success(prepareMessage('Success', message), {
            ...settingsNotify,
            timeout: notClose ? 1000000000 : settingsNotify.timeout
        })
    },
    error: (message: string, notClose: boolean = false): void => {
        Alert.error(prepareMessage('Error', message), {
            ...settingsNotify,
            timeout: notClose ? 1000000000 : settingsNotify.timeout
        })
    },
    info: (message: string, notClose: boolean = false): void => {
        Alert.info(prepareMessage('Info', message), {
            ...settingsNotify,
            timeout: notClose ? 1000000000 : settingsNotify.timeout
        })
    },
    warning: (message: string, notClose: boolean = false): void => {
        Alert.warning(prepareMessage('Warning', message), {
            ...settingsNotify,
            timeout: notClose ? 1000000000 : settingsNotify.timeout
        })
    },

    closeAll: (): void => {
        Alert.closeAll()
    }
}

export default notify;