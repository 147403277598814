import React, {useEffect, useState, useCallback, useMemo, PropsWithChildren} from 'react';
import { FitBoundsOptions, LatLng, Map as LeafletMap } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';

import './styles.scss';
import 'leaflet/dist/leaflet.css';

import Markers from '@components/map/markers';


interface IMapOffset {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
}

interface IMapPropTypes {
    points?: any[];
    selectedPoint?: any;
    mapOffset?: IMapOffset;
    onPointClick?: (latLng: [number, number]) => void;
    onZoomed?: (zoomLevel: number) => void;
    zoomInit?: number;
}

const Map: React.FC<IMapPropTypes> = (props: PropsWithChildren<IMapPropTypes>) => {
    const { points = [], selectedPoint, mapOffset, onPointClick, children, onZoomed, zoomInit } = props;
    const boundsOption = useMemo(() => ({
        paddingTopLeft: [mapOffset?.left || 0, mapOffset?.top || 0],
        paddingBottomRight: [mapOffset?.right || 0, mapOffset?.bottom || 0],
        maxZoom: zoomInit || 8
    }), [mapOffset, zoomInit])

    const [map, setMap] = useState<LeafletMap |null>(null);
    const [currentCoords, setCurrentCoords] = useState<LatLng>(new LatLng(0, 0))

    useEffect(() => {
        if (selectedPoint && points.length) {
            map?.flyToBounds([selectedPoint.place.coordinates], boundsOption as FitBoundsOptions);
        }
    }, [selectedPoint, map, points, boundsOption]);

    const isCreate = useCallback((currentMap) => {
        setMap(currentMap);
        currentMap?.invalidateSize();
        currentMap.on('zoomend', () => {
            if (onZoomed) {
                onZoomed(currentMap.getZoom())
            }
        })
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            setCurrentCoords(new LatLng(latitude, longitude));
            currentMap?.flyToBounds(Array([latitude, longitude]), boundsOption as FitBoundsOptions);


        });
        currentMap.getContainer().addEventListener('contextmenu', (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const latlng = currentMap.mouseEventToLatLng(e);
            if (onPointClick) {
                onPointClick([latlng.lat, latlng.lng])
            }
        });

    }, [onZoomed, boundsOption, onPointClick])

    return (
        <MapContainer
            center={currentCoords}
            zoom={4}
            scrollWheelZoom
            className='map'
            whenCreated={isCreate}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://api.mapbox.com/styles/v1/jsparrow2006/ckvxpzvfm64vl14qnixqbjbbx/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoianNwYXJyb3cyMDA2IiwiYSI6ImNrdnpjNGdnZzA4cGIzMHJvZXNoOXUyaWEifQ.AwD7dwY8ijAAfcJR7QoUzQ"
            />
            <MarkerClusterGroup showCoverageOnHover={false}>
                {
                    points.map((point, i) => (
                        <Marker key={`place_point_${i}`} position={point.coordinates} icon={Markers.marker}>
                            <Popup>
                                {point.name} <br /> {point.description}
                            </Popup>
                        </Marker>
                    ))
                }
            </MarkerClusterGroup>
            {children}
        </MapContainer>
    );
};

Map.defaultProps = {
    points: []
}

export default Map;
