import React from 'react';
import { Link } from 'react-router-dom';

import UserMenu from '@components/userMenu'
import LanguageSelector from '@components/languageSelector';

import buildData from '../../build.json';

import './styles.scss';

const Header: React.FC = () => {

    return (
        <div className="header">
            <div className="logo">
                <Link to='/' className='logoLink'>
                    <div className="icon" />
                    <div className="name">walkplaces</div>
                </Link>
                {
                    buildData.number && (
                        <span className="buildNumber"><a href={buildData.link} target='blank'>[{buildData.number}]</a></span>
                    )
                }
            </div>
            <div className="items">
                <LanguageSelector className='headerLanguageSelector' />
                <UserMenu />
            </div>
        </div>
    );
}

export default Header;