import React, { useCallback, useRef, useState } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import imageLink from 'helpers/imageLink';
import userHasRoles from 'helpers/roles/userHasRoles'

import mainMenu from 'constants/mainMenu';

import { userApi } from '@api/index'

import { IUserData } from '@reducers/models/user';

import useSubscribeState from '@hooks/useSubscribeState';
import useIsUserLoggedIn from '@hooks/useIsUserLoggedIn';
import useTranslate from '@hooks/userTranslate';

import Avatar from 'components/avatar';
import Button from 'components/common/button';
import MobileUserCard from 'components/userMenu/components/mobileUserCard';

import './styles.scss';

interface IDropDownItemProps {
    label: string;
    link?: string;
    userRoles: string[];
    requiredRoles: string[];
    onClick?: (event: React.SyntheticEvent) => void;
}

const DropDownItem: React.FC<IDropDownItemProps> = (props: IDropDownItemProps) => {
    const { label, onClick, link = '', userRoles, requiredRoles } = props;

    return userHasRoles(requiredRoles, userRoles) ? (
        <li className='dropDownItem' onClick={onClick}>
            <Link to={link}>
                {label}
            </Link>
        </li>
    ) : null
}

const UserMenu: React.FC<RouteComponentProps> = ( props: RouteComponentProps ) => {
    const currentUser = useSubscribeState<IUserData>('currentUser');
    const selectRef = useRef<HTMLDetailsElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);
    const isUserLoggedIn = useIsUserLoggedIn();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const t = useTranslate();

    const userRoles = (currentUser.role || '').split(',')

    const handleClick = useCallback(() => {
        const { history } = props
        if(isUserLoggedIn) {
            setIsOpen(!isOpen)
        } else {
            history.push('/login', { redirect: history.location.pathname })
        }
    }, [setIsOpen, isOpen, isUserLoggedIn, props]);

    const handleMenuItemClick = useCallback(() => {
        selectRef.current?.removeAttribute('open')
    }, [selectRef]);

    const handleLogOut = useCallback(async () => {
        await userApi.logoutUser()
        setIsOpen(false)
    }, [setIsOpen])

    return (
        <div className='userLogin'>
            {!isUserLoggedIn ? (
                <Button className='loginButton' caption='Log in / Sign up' theme='secondary' onClick={handleClick} />
            ) : (
                <details ref={selectRef}>
                    <summary className='openState'>
                        <div className='burgerButton'>
                            <div />
                        </div>
                        <div className='userBlock'>
                            <span>{`${currentUser.firstname} ${currentUser.lastname}`}</span>
                            {isUserLoggedIn && <Avatar width='48px' height='48px' link={imageLink(currentUser.avatar)} />}
                        </div>
                    </summary>
                   <div className='userMenuBlock'>
                       <div className='userInfoBlock'>
                           <MobileUserCard
                               user={currentUser}
                               avatarLink={imageLink(currentUser.avatar)}
                           />
                       </div>
                       <ul
                           ref={optionsRef}
                           className='openState'
                           onClick={handleMenuItemClick}
                       >
                           {
                               mainMenu.map((menuItem) => {
                                   return <DropDownItem
                                       key={menuItem.link}
                                       link={menuItem.link}
                                       label={t(menuItem.caption)}
                                       userRoles={userRoles}
                                       requiredRoles={menuItem.roles}
                                   />
                               })
                           }
                           <DropDownItem
                               label={t('userMenu.logOut')}
                               onClick={handleLogOut}
                               userRoles={[]}
                               requiredRoles={[]}
                           />
                       </ul>
                   </div>
                </details>
            )}
        </div>
    );
}

export default withRouter(UserMenu);