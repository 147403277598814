import React, {useCallback, useState, useRef, InputHTMLAttributes, ChangeEvent} from 'react';
import { FiX } from 'react-icons/fi';

import './styles.scss';

export interface ITextAreaPropTypes extends InputHTMLAttributes<HTMLTextAreaElement>{
    theme?: 'main' | 'secondary' | 'warning' | 'error' | 'disabled';
    error?: string;
    isClear?: boolean;
    rows?: number;
}

const TextArea: React.FC<ITextAreaPropTypes> = (props: ITextAreaPropTypes) => {
    const { title, theme = 'main', onChange, error, required, isClear, rows } = props;

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const [isFocus, setIsFocus] = useState<boolean>(false)

    const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange(event)
        }
    }, [onChange])

    const handleFocus = useCallback(() => {
        setIsFocus(true);
    }, [])

    const handleLeave = useCallback(() => {
        setIsFocus(false);
    }, [])

    const handleClear = useCallback(() => {
        if(inputRef.current) {
            inputRef.current.value = '';
            if (onChange) {
                onChange({
                    target: {value: ''}
                } as ChangeEvent<HTMLTextAreaElement>)
            }
        }

    }, [inputRef, onChange]);


    const nativeInputProps = { ...props }
    delete nativeInputProps.isClear;

    return (
        <div className={`textArea ${theme} ${isFocus && 'textAreaFocus'} ${error && 'textAreaError'}`}>
            <div className='inputWrapper'>
                <span className='textAreaCaption'>{title}{required && '*'}{error && ` (${error})`}</span>
                <textarea
                    ref={inputRef}
                    {...nativeInputProps}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleLeave}
                    rows={rows}
                />
            </div>
            <div className='textAreaIcons'>
                {(isClear && inputRef.current?.value) && (
                    <div className='textAreaIcon action' onClick={handleClear}>
                        <FiX />
                    </div>
                )}
            </div>
        </div>
    );
};

TextArea.defaultProps = {
    theme: 'main'
}

export default TextArea;
