import React from 'react';

import useTranslate from '@hooks/userTranslate';

import PlacesItem from './placesItem';


import './styles.scss';

interface IPlacesListPropTypes {
    places: any[];
    onChange?: (place: any, index: number) => void;
}

const PlacesList: React.FC<IPlacesListPropTypes> = (props) => {
    const { places, onChange } = props;
    const t = useTranslate()

    return (
        <div className='placesListWrapper'>
            <div className="caption">
                {t('excursion.excursions_today')} ({places.length})
            </div>
            <div className="list">
                {places.map((place, i) => <PlacesItem key={`item_place-${i}`} onChange={onChange} place={place} index={i} />)}
            </div>
        </div>
    );
};

PlacesList.defaultProps = {
    places: []
}

export default PlacesList;
