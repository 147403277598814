import React from 'react';

import { IUserData } from '@reducers/models/user';

import Avatar from '@components/avatar';
import LanguageSelector from '@components/languageSelector';

import './styles.scss';

interface IMobileUserCardProps {
    avatarLink?: string;
    user: IUserData;
}

const MobileUserCard: React.FC<IMobileUserCardProps> = (props: IMobileUserCardProps) => {
    const {
        avatarLink,
        user
    } = props;

    return (
        <div className='mobileUserCard'>
            <Avatar width='120px' height='120px' link={avatarLink} />
            <LanguageSelector className='mobileUserCardLanguageSelector' />
            <span className='userName'>{`${user.firstname} ${user.lastname}`}</span>
        </div>
    );
}

export default MobileUserCard;