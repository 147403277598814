import reduxFetch from '@helpers/reduxFetch';

import { ILoggedInUser } from 'api/userApi/types/ILoggedInUser'
import { IRequestedSignUpUser } from 'api/userApi/types/IRequestedSignUpUser'

import {
    LOGIN_USER,
    SIGNUP_USER,
    CONFIRM_USER,
    LOGOUT_USER
} from '@constants/requests';

const userApi = {
    loginUser: async (userData: any): Promise<ILoggedInUser> => {
        return reduxFetch.post(`/login`, { requestType: LOGIN_USER, body: userData })
    },
    signUpUser: async (userData: any): Promise<IRequestedSignUpUser> => {
        return reduxFetch.post(`/signup`, { requestType: SIGNUP_USER, body: userData })
    },
    confirmUser: async (userData: any): Promise<ILoggedInUser> => {
        return reduxFetch.post(`/confirmUser`, { requestType: CONFIRM_USER, body: userData })
    },
    logoutUser: async (): Promise<void> => {
        return reduxFetch.get(`/logout`, { requestType: LOGOUT_USER })
    },
}

export default userApi;