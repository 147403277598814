import React, { useCallback } from 'react';

import { IGallery } from '@components/slider';
import FileDialog from '@components/fileDialog';
import RoundButton from '@components/common/roundButton'

import './styles.scss';

interface IGalleryUploaderProps {
    images: IGallery[];
    activeImage: number;
    onChangeImagesList: (images: IGallery[]) => void;
}

const GalleryUploader: React.FC<IGalleryUploaderProps> = (props: IGalleryUploaderProps) => {
    const { images, activeImage, onChangeImagesList } = props;

    const onSaveFiles = useCallback((files: File[] ) => {
        const newImages = files.map((file: File) => ({
            description: '',
            url: URL.createObjectURL(file),
            file
        }));
        onChangeImagesList([...images, ...newImages])
    }, [images, onChangeImagesList])

    const handleDeleteImage = useCallback(() => {
        const newImageList = images.filter((item, i) => i !== activeImage);
        onChangeImagesList(newImageList);
    }, [images, onChangeImagesList, activeImage])

    const handleDropFiles = useCallback((e: any) => {
        e.preventDefault()
        e.stopPropagation()
        onSaveFiles([...e.dataTransfer.files])
    }, [onSaveFiles])

    const handleDragIn = useCallback((e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handleDragOver = useCallback((e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    return (
        <div
className='galleryUploader'
             onDragEnter={handleDragIn}
             onDrop={handleDropFiles}
             onDragOver={handleDragOver}
        >
            <div className='imagesAction'>
                <FileDialog onSelectFiles={onSaveFiles} />
                <RoundButton
                    disabled={images.length < 1}
                    theme='error' icon='delete'
                    onClick={handleDeleteImage}
                />
            </div>
        </div>
    );
}

export default GalleryUploader;