import React, { useState, useCallback, PropsWithChildren, useEffect } from 'react';

import './styles.scss';

export interface IGallery {
    description: string;
    url: string;
    file?: File;
}

interface ISliderProps {
    images: IGallery[];
    onImageSelect?: (id: number) => void;
}

const Slider: React.FC<PropsWithChildren<ISliderProps>> = (props: PropsWithChildren<ISliderProps>) => {
    const { images, onImageSelect, children } = props;
    const [active, setActive] = useState<number>(0)

    useEffect(() => {
        if (images[active] === undefined && images.length !== 0) {
            setActive(active - 1)
        }
        if (onImageSelect) {
            onImageSelect(active)
        }
    }, [active, onImageSelect, setActive, images])

    const handlePrev = useCallback((event: React.SyntheticEvent) => {
        event.stopPropagation();
        setActive(active !== 0 ? active - 1 : images.length - 1)
    }, [active, setActive, images]);

    const handleNext = useCallback((event: React.SyntheticEvent) => {
        event.stopPropagation();
        setActive(active !== images.length - 1 ? active + 1 : 0)
    }, [active, setActive, images])

    return (
        <div className='slider'>
            {
                images.map((image, index) => {
                    return <div key={`galleryImage-${image.url.slice(-10)}`} className={`galleryImage ${active === index && 'active'}`} style={{backgroundImage: `url(${image.url})`}} />
                })
            }
            {children}
            {
                images.length > 1 && (
                    <>
                        <div className='arrow prev' onClick={handlePrev} />
                        <div className='points'>
                            {
                                images.map((image, index) => {
                                    return <div key={`point-${image.url.slice(-10)}`} className={`point ${active === index && 'active'}`} />
                                })
                            }
                        </div>
                        <div className='arrow next' onClick={handleNext} />
                    </>
                )
            }
        </div>
    );
}

export default Slider;