import ROLES from 'constants/userRoles';

export interface IMainMenu {
    caption: string;
    link: string;
    roles: string[];
    action?: () => void;
}

const mainMenu: IMainMenu[] = [
    {
        caption: 'userMenu.profile',
        link: '/profile',
        roles: []
    },
    {
        caption: 'userMenu.myExcursions',
        link: '/myExcursions',
        roles: []
    },
    {
        caption: 'userMenu.createExcursion',
        link: '/excursion/new/edit',
        roles: [
            ROLES.CREATOR,
            ROLES.ADMIN
        ]
    }
]

export default mainMenu