import React from 'react';
import { FaRegTimesCircle } from "react-icons/fa";
 
import useTranslate from '@hooks/userTranslate';

interface IClickActive {
  onClickActive: () => void;
}

const ActiveBar: React.FC<IClickActive> = ({ onClickActive }) => {
  const t = useTranslate();

  return(
    <div className="overlay"> 
        <div className="Activities-bar">
          <div className="blind" />
          <span>{t('activeBar.availableActivities')}</span>
          <FaRegTimesCircle className="ActiveClose" onClick={onClickActive} />
          <div className="activites-item">
            <div className="btn">{t('activeBar.visitTheOldChurch')}</div>
            <div className="btn">{t('activeBar.goToPark')}</div>
            <div className="btn">{t('activeBar.goToTheCafe')}</div>
            <div className="btn">{t('activeBar.rideOnTheSubway')}</div>
            <div className="btn">{t('activeBar.interestingBuildingsLookAt')}</div>
            <div className="btn">{t('activeBar.flyWithABalloon')}</div>
            <div className="btn">{t('activeBar.thePeopleOnTheStreetSpeakTo')}</div>
          </div>
        </div>
    </div>
  )
}

export default ActiveBar;