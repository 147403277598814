import React, { ReactElement, useMemo } from 'react';
import ReactModal from 'react-modal';
import { MdOutlineClose } from 'react-icons/md'

import './styles.scss';

interface IModal {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement;
    footer?: ReactElement;
    width?: string;
    caption?: string;
}

const Modal: React.FC<IModal> = (props: IModal) => {

    const {
        isOpen,
        onClose,
        children,
        width = '70%',
        caption = '',
        footer
    } = props;

    const modalStyle = useMemo(() => {
        return {
            overlay: {
                zIndex: 99999999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(128,171,234,0.37)',
            },
            content: {
                width,
                height: 'auto'
            }
        }
    }, [width])

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className='modal'
            contentLabel="Example Modal"
            style={modalStyle}
            ariaHideApp={false}
        >
            <> 
                <div className='modalHeader'>
                    <h2>{caption}</h2>
                    <div className='closeButton' onClick={onClose}><MdOutlineClose /></div>
                </div>
                {children}
                <div className='modalFooter'>
                    {footer}
                </div>
            </>
        </ReactModal>
    );
}

export default Modal;