import React, { useState, useCallback } from 'react';

import Map from '@components/map';
import MapPath, { IPathPoint } from '@components/map/mapPath';


import './styles.scss';

const MIN_ZOOM_FOR_VIEW_PATH = 11;

interface IEditMapProps {
    onEditPathPoints: (points: IPathPoint[]) => void;
    pathPoints: IPathPoint[];
}

const EditMap: React.FC<IEditMapProps> = (props: IEditMapProps) => {
    const { onEditPathPoints, pathPoints } = props;
    const [isShowAllPath, setIsShowAllPath] = useState<boolean>(true);

    const handleUpdatePath = useCallback((path: IPathPoint[]) => {
        onEditPathPoints(path)
    }, [onEditPathPoints])
    console.log(pathPoints)

    const handlePointClick = useCallback((latLng: [number, number]) => {
        // console.log(latLng)
        // console.log(pathPoints)
        const point = {
            typePoint: 'STEP',
            latitude: latLng[0],
            longitude: latLng[1]
        };
        // console.log(point)
        onEditPathPoints([...pathPoints, point] as IPathPoint[]);
    }, [onEditPathPoints, pathPoints]);

    const handleEditPoint = useCallback((index: number, point: IPathPoint) => {
        // console.log(pathPoints)
        const newPoints = [...pathPoints];
        newPoints[index] = point;
        handleUpdatePath(newPoints)
    }, [pathPoints, handleUpdatePath]);

    const handleZoom = useCallback((zoomLevel: number) => {
        setIsShowAllPath(zoomLevel > MIN_ZOOM_FOR_VIEW_PATH)
    }, [setIsShowAllPath])

    const handleDeletePoint = useCallback((index: number) => {
        const newPoints = pathPoints.filter((point, i) => i !== index);
        handleUpdatePath(newPoints)
    }, [pathPoints, handleUpdatePath])

    return (
        <div className='editMap'>
            <Map
                points={[]}
                selectedPoint={[]}
                onPointClick={handlePointClick}
                onZoomed={handleZoom}
                zoomInit={12}
            >
                <MapPath
                    pathPoints={pathPoints}
                    isEdit
                    isShowAllPath={isShowAllPath}
                    onEditPoint={handleEditPoint}
                    onDeletePoint={handleDeletePoint}
                />
            </Map>
        </div>
    );
}

export default EditMap;