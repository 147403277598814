import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Alert from 'react-s-alert';
import { store } from '@helpers/reduxFetch';

import Header from '@components/header';
import Routes from '@components/routes';

import './i18nextInit';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './index.scss';

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <Alert stack={{ limit: 5 }} />
            <Router>
                <div className='page'>
                    <Header />
                    <div className='content'>
                        <Routes />
                    </div>
                </div>
            </Router>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);
