import reduxFetch from '@helpers/reduxFetch';

import { ICityDictionary} from 'types/ICityDictionary';
import { ICountryDictionary} from 'types/ICountryDictionary';
import { ILanguageDictionary} from 'types/ILanguageDictionary';

import {
    LANGUAGE_DICTIONARY,
    CITY_DICTIONARY,
    COUNTRY_DICTIONARY
} from '@constants/requests';

const dictionaryApi = {
    languages: async (search: string | undefined): Promise<ILanguageDictionary[]> => {
        return reduxFetch.get(`/dictionary/languages${search ? `?name=${search}` : ''}`, { requestType: LANGUAGE_DICTIONARY })
    },
    cities: async (search: string | undefined): Promise<ICityDictionary[]> => {
        return reduxFetch.get(`/dictionary/cities${search ? `?name=${search}` : ''}`, { requestType: CITY_DICTIONARY })
    },
    countries: async (search: string | undefined): Promise<ICountryDictionary[]> => {
        return reduxFetch.get(`/dictionary/countries${search ? `?name=${search}` : ''}`, { requestType: COUNTRY_DICTIONARY })
    },
}

export default dictionaryApi;