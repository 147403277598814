import React, { PropsWithChildren, ReactElement, useCallback } from 'react';

import './styles.scss';

interface IButtonPropTypes {
    caption?: string | ReactElement;
    theme?: 'main' | 'light' | 'secondary' | 'warning' | 'error' | 'disabled';
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
}

const Button: React.FC<IButtonPropTypes> = (props: PropsWithChildren<IButtonPropTypes>) => {
    const { caption, children, theme = 'main', onClick, disabled, className } = props;

    const handleClick = useCallback(() => {
        if(!disabled && onClick) {
            onClick()
        }
    }, [onClick, disabled])

    return (
        <div className={`button ${theme} ${disabled && 'disabled'} ${className ? className : ''}`} onClick={handleClick}>
            {caption}
            {children}
        </div>
    );
};

Button.defaultProps = {
    caption: '',
    theme: 'main'
}

export default Button;
