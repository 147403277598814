import React, {ChangeEvent, useCallback, useRef} from 'react';

import RoundButton from '@components/common/roundButton'

import './styles.scss';

interface IFileDialogProps {
    onSelectFiles: (files: File[]) => void;
}

const FileDialog: React.FC<IFileDialogProps> = (props: IFileDialogProps) => {
    const { onSelectFiles } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const handleOpenDialog = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }, [inputRef])

    const handleSelectFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            // @ts-ignore
            const fileList = Object.keys(files).map((el: string) => files[el])
            onSelectFiles(fileList)
        }
    }, [onSelectFiles])

    return (
        <div className='fileDialog'>
            <input
                className='hiddenInput'
                ref={inputRef}
                multiple
                onChange={handleSelectFile}
                type='file'
            />
            <RoundButton icon='add' theme='secondary' onClick={handleOpenDialog} />
        </div>
    );
}

export default FileDialog;