import * as Translator from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locals/en/translation.json';
import translationDE from './locals/de/translation.json';
import translationRU from './locals/ru/translation.json';
import translationUK from './locals/uk/translation.json';
import translationBY from './locals/by/translation.json';
import translationFR from './locals/fr/translation.json';

const fallbackLng = ['en'];
export const availableLanguages = ['en', 'de', 'fr', 'ru', 'by', 'uk'];

const browserLanguage = (navigator.languages ? navigator.languages[0] : navigator.language).split('-')[0];
const userLanguage = localStorage.getItem('userLanguage') || browserLanguage;

const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    },
    ru: {
        translation: translationRU
    },
    uk: {
        translation: translationUK
    },
    by: {
        translation: translationBY
    },
    fr: {
        translation: translationFR
    }
};

Translator.use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false
        }
    })
    .then(() => {
        console.log('i18n init!');
    });

Translator.changeLanguage(userLanguage).then();

const i18n = Translator;

export default i18n;
