import React, { useState } from 'react';
import { withRouter, RouteComponentProps} from 'react-router-dom';
import { FaComments, FaExpandArrowsAlt, FaSignOutAlt} from "react-icons/fa";

import useTranslate from '@hooks/userTranslate';

import ChatBar from './component/ChatBar/ChatBar';
import ActiveBar from './component/ActiveBar/ActiveBar';

import './styles.scss';

interface IExcursionStreamProps {

}

const ExcursionStream: React.FC<RouteComponentProps<IExcursionStreamProps>> = () => {
  const [chatBar, setChatBar] = useState<boolean>(false);
  const [visibleActive, setVisibleActive] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const t = useTranslate();

  return(
    <div className={`wrapper ${fullScreen && 'fullScreen'}`}>
      { chatBar && <ChatBar onClickChat={() => setChatBar(false)} /> }
      { visibleActive && <ActiveBar onClickActive={() => setVisibleActive(false)} /> }
      <div className="containerStream">
        <span className="timing">4:36</span>
        <div className="btn" onClick={() => setVisibleActive(true)}>{t('excursionSream.activitiesAvailable')}</div>
        <div className="btn-section">
          <FaExpandArrowsAlt className="Icon-stream" onClick={() => setFullScreen(!fullScreen)} />
          <FaComments className="Icon-stream" onClick={() => setChatBar(true)} />
          <FaSignOutAlt className="Icon-stream" />
        </div>
      </div>  
    </div>
  );
}

export default withRouter(ExcursionStream);