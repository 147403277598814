export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const SIGNUP_USER = 'SIGNUP_USER';
export const CONFIRM_USER = 'CONFIRM_USER';
export const LANGUAGE_DICTIONARY = 'LANGUAGE_DICTIONARY';
export const CITY_DICTIONARY = 'CITY_DICTIONARY';
export const COUNTRY_DICTIONARY = 'COUNTRY_DICTIONARY';
export const CREATE_EXCURSION = 'COUNTRY_DICTIONARY';
export const EDIT_EXCURSION = 'EDIT_EXCURSION';
export const GET_EXCURSION = 'GET_EXCURSION';