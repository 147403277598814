import { useSelector } from 'react-redux';

import { IStore } from '@reducers/index';

const useSubscribeState = <T, V = undefined>(stateName: keyof IStore, field?: keyof T | undefined): T => {
    const data = useSelector<IStore>((state) => state[stateName as keyof IStore]) as T;

    if(field) {
        // @ts-ignore
        return data[field ] as V
    } 
        return data 
    
}

export default useSubscribeState