import L, { Icon } from 'leaflet';

import marker from '@assets/marker.svg';
import activeMarker from '@assets/activeMarker.svg';
import checkPointMarker from '@assets/checkPointMarker.svg';
import dotMarker from '@assets/dotMarker.svg';

interface IMarkers {
    marker: Icon;
    activeMarker: Icon;
    checkPoint: Icon;
    dotMarker: Icon;
}

const Markers: IMarkers = {
    marker: L.icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: [20, 35],
        popupAnchor: [0, 0],
        iconSize: [40, 40],
        className: 'marker-icon'
    }),
    activeMarker: L.icon({
        iconUrl: activeMarker,
        iconRetinaUrl: activeMarker,
        iconAnchor: [28, 35],
        popupAnchor: [0, 0],
        iconSize: [56, 62],
        className: 'marker-icon'
    }),
    checkPoint: L.icon({
        iconUrl: checkPointMarker,
        iconRetinaUrl: checkPointMarker,
        iconAnchor: [16, 16],
        popupAnchor: [0, 0],
        iconSize: [32, 32],
        className: 'marker-icon'
    }),
    dotMarker: L.icon({
        iconUrl: dotMarker,
        iconRetinaUrl: dotMarker,
        iconAnchor: [6, 6],
        popupAnchor: [0, 0],
        iconSize: [12, 12],
        className: 'marker-icon'
    })
}

export default Markers;