import React, { PropsWithChildren, useCallback } from 'react';
import { FiFile, FiPlus, FiTrash2 } from 'react-icons/fi';

import './styles.scss';

const ICON_MAP = {
    'file': <FiFile />,
    'add' : <FiPlus />,
    'delete': <FiTrash2 />
}

interface IRoundButtonPropTypes {
    icon?: 'file' | 'add' | 'delete';
    theme?: 'main' | 'secondary' | 'warning' | 'error' | 'disabled';
    onClick?: () => void;
    disabled?: boolean;
}

const RoundButton: React.FC<IRoundButtonPropTypes> = (props: PropsWithChildren<IRoundButtonPropTypes>) => {
    const { icon = 'file', theme = 'main', onClick, disabled } = props;
    const TypeIcon = ICON_MAP[icon] || null;

    const handleClick = useCallback(() => {
        if(!disabled && onClick) {
            onClick()
        }
    }, [onClick, disabled])

    return (
        <div className={`roundButton ${theme} ${disabled && 'disabled'}`} onClick={handleClick}>
            {TypeIcon}
        </div>
    );
};

RoundButton.defaultProps = {
    icon: 'file',
    theme: 'main'
}

export default RoundButton;
