import React, { ReactElement } from 'react'
import { Route } from 'react-router-dom';
import userHasRoles from 'helpers/roles/userHasRoles'

import { IUserData } from '@reducers/models/user';

import useSubscribeState from '@hooks/useSubscribeState';

interface IPrivateRoute {
    path: string;
    exact?: boolean;
    children: ReactElement;
    roles: string[];
}

const PrivateRoute: React.FC<IPrivateRoute> = (props: IPrivateRoute) => {
    const {
        path,
        exact,
        children,
        roles
    } = props;

    const currentUser = useSubscribeState<IUserData>('currentUser');
    const userRoles = (currentUser.role || '').split(',')

    if (userHasRoles(roles, userRoles)) {
        return (
            <Route
                exact={exact}
                path={path}
            >
                {children}
            </Route>
        )
    }

    return null
}

export default PrivateRoute