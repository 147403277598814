import React, { useCallback, useState } from 'react';

import Map from '../../components/map';
import PlacesList from '../../components/placesList';

import places from '../../assets/places.json'

import './styles.scss';

 export interface ISelectedPlaceTypes {
    index: number;
    place: any;
}


const MainPage: React.FC = () => {

    const [selectedPlace, setSelectedPlace] = useState<ISelectedPlaceTypes | null>(null)

    const handleSelectPlace = useCallback((place: any, index: number) => {
        setSelectedPlace({
            place,
            index
        })
    }, [])

    return (
      <>
          <Map points={places} selectedPoint={selectedPlace} mapOffset={{right: 700}} />
          <PlacesList onChange={handleSelectPlace} places={places} />
      </>
    );
};

export default MainPage;
